import { Router } from '@angular/router';
import { DataService } from './../../data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(dataservice: DataService, router: Router) {
    dataservice.logout()

    router.navigate(["/login"])

  }

  ngOnInit() {

  }

}
