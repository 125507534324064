import { DataService } from './../../data.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, Data } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  pass: String
  newpass: String
  repass: String

  errMsg: String

  loading: Boolean = false

  constructor(private http: HttpClient, private router: Router, private dataService: DataService) { }

  ngOnInit() {

  }

  changePass() {
    this.errMsg = undefined
    if (this.loading) {
      return
    }
    if (!this.pass || this.pass.length < 8) {
       this.errMsg = "Nhập mật khẩu hiện tại ít nhất 8 kí tự"
       return
    }

    if (!this.newpass || this.newpass.length < 8) {
      this.errMsg = "Nhập mật khẩu mới ít nhất 8 kí tự"
      return
   }

   if (this.repass !== this.newpass) {
    this.errMsg = "Gõ lại mật khẩu mới không giống nhau (Ô thứ 2 và thứ 3)"
    return
    }

    this.loading = true
    this.http.post<any>('/api/user/change-password', {
      password: this.pass,
      new_password: this.newpass,
    }).subscribe(response=> {
      this.loading = false
      if (response.success) {
        this.dataService.logout()
        this.router.navigate(['login'])
        alert('Thay đổi mật khẩu thành công! Vui lòng đăng nhập lại')
        return
      } 

      if (response.message) {
        this.errMsg = response.message
      }
    }, err => {
      this.loading = false
      this.errMsg = JSON.stringify(err)
    })
  }
}
