import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {

  registForm: FormGroup

  errorMessage: String

  loading: Boolean

  constructor(private router: Router, private fb: FormBuilder,
    private http: HttpClient) {
    
  }

  ngOnInit() {
    this.registForm = this.fb.group({
      fullname: new FormControl('', [ 
        Validators.required,
        Validators.minLength(8)
      ]),
      email: new FormControl('', [ 
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
          Validators.minLength(8), 
          Validators.required
      ]),
      repassword: new FormControl('', [
        Validators.minLength(8), 
        Validators.required
      ])
    });
  }

  public register() {
    this.errorMessage = undefined
    if (this.registForm.valid) {

      if (this.registForm.controls.password.value != this.registForm.controls.repassword.value) {
        this.errorMessage = "Mật khẩu không trùng khớp"
        return
      }

      this.loading = true
      
      this.http.post<any>('/api/auth/register', {
        full_name: this.registForm.value.fullname,
        email: this.registForm.value.email,
        password: this.registForm.value.password
      }).subscribe(response => {
        console.log(response +'');
        this.loading = false
        if(response.success) {
          this.gotoLogin();
        } else if(response.message) {
          this.errorMessage = response.message
        } else {
          this.errorMessage = 'Unknown Error!'
        }
      }, err => {
        this.loading = false
        if (err && err.message) {
          this.errorMessage = err.message
        } else {
          this.errorMessage = 'Unknown Error!'
        }
      })
    } else {

      // Name
      if (this.registForm.controls.fullname.invalid) {
        this.errorMessage = 'Tên ít nhất 8 kí tự'
        return
      }

      if (this.registForm.controls.email.invalid) {
        if (this.registForm.controls.email.errors.email) {
          this.errorMessage = 'Địa chỉ email không hợp lệ'
        } else {
          this.errorMessage = 'Vui lòng nhập email!'
        }
        return
      }

      if (this.registForm.controls.password.invalid || this.registForm.controls.repassword.invalid) {
        this.errorMessage = "Nhập mật khẩu ít nhất 8 kí tự"
        return
      }

      this.errorMessage =  "Không biết lỗi gì"
    }
  }


  gotoLogin() {
    this.router.navigate(['/login']);
  }
}
