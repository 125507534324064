import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



  public exportAsExcelFileTemplate(json: any[], excelFileName: string): void {

    const header = Object.keys(json[0]); // columns name

    var wscols = [];
    for (var i = 0; i < header.length; i++) {  // columns length added
      wscols.push({ wch: header[i].length + 5 })
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet["!cols"] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelDanhSachBaiPhanTich(json: any[], json2: any[], excelFileName: string): void {
    const header = Object.keys(json[2]); // columns name

    var wscols = [];
    wscols.push({ wch: 5 })
    for (var i = 1; i < header.length; i++) {  // columns length added
      wscols.push({ wch: 20, width: 20 })
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    worksheet["!cols"] = wscols;
    // worksheet['B1'].s = {font: {bold : true}};
    var merges = [];
    merges.push({ s: "A1", e: "I1" })
    worksheet["!merges"] = merges;
    var rows = [];
    rows.push({ hpx: 50 })
    worksheet["!rows"] = rows;



    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    worksheet2["!cols"] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'Tổng hợp': worksheet, 'data Chi tiết': worksheet2 }, SheetNames: ['Tổng hợp', 'data Chi tiết'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  public exportAsExcelDanhSachTinhLuong(json: any[], json2: any[], json3: any[], excelFileName: string): void {
    const header = Object.keys(json[2]); // columns name

    var wscols = [];
    wscols.push({ wch: 5 })
    for (var i = 1; i < header.length; i++) {  // columns length added
      wscols.push({ wch: 20, width: 20 })
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    worksheet["!cols"] = wscols;
    worksheet["A2"].s = {
      font: {
        sz: 24,
        bold: true,
        color: { rgb: "FFFFAA00" }
      },
      alignment: {
        vertical: "center"
      }
    };
    // worksheet['B1'].s = {font: {bold : true}};
    var merges = [];
    merges.push({ s: "A1", e: "I1" })
    worksheet["!merges"] = merges;
    var rows = [];
    rows.push({ hpx: 50 })
    worksheet["!rows"] = rows;





    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2, { skipHeader: true });
    worksheet2["!cols"] = wscols;
    // worksheet['B1'].s = {font: {bold : true}};
    var merges = [];
    merges.push({ s: "A1", e: "I1" })
    worksheet2["!merges"] = merges;
    var rows = [];
    rows.push({ hpx: 50 })
    worksheet2["!rows"] = rows;


    const worksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json3, { skipHeader: true });
    worksheet3["!cols"] = wscols;
    // worksheet['B1'].s = {font: {bold : true}};
    var merges = [];
    merges.push({ s: "A1", e: "I1" })
    worksheet3["!merges"] = merges;
    var rows = [];
    rows.push({ hpx: 50 })
    worksheet3["!rows"] = rows;



    // const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    // worksheet2["!cols"] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'Tổng hợp': worksheet, 'Tổng quan': worksheet2, 'ChiTiet': worksheet3 }, SheetNames: ['Tổng hợp', 'Tổng quan', 'ChiTiet'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }




}
