import { DataService } from './../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

  errMsg: String
  userId: String
  email: String
  loading = false

  newpass: String
  repass: String


  constructor(private http: HttpClient, private router: Router, 
    private dataService: DataService, private route: ActivatedRoute) { 
  }

  ngOnInit() {
    this.http.post<any>('/api/pass-control/check-reset-pass',{
      token: this.route.snapshot.paramMap.get('token')
    }).subscribe(response => {
      if (response.success) {
        this.userId = response.data.id
        this.email = response.data.email
      } else {
        this.router.navigate['login']
      }
    }, err => {
      this.router.navigate['login']
    })
  }

  createPass() {
    if (this.loading) {
      return
    }

    if (!this.newpass || this.newpass.length < 8) {
      this.errMsg = "Nhập mật khẩu mới ít nhất 8 kí tự"
      return
   }

   if (this.repass !== this.newpass) {
    this.errMsg = "Gõ lại mật khẩu mới không giống nhau ( Ô thứ 2 và thứ 3"
    return
    }
    
    this.loading = true

    this.http.put<any>('/api/pass-control/'+ this.userId, {
      new_pass: this.newpass
    }).subscribe(response=> {
      this.loading = false
      if (response.success) {
        this.dataService.logout()
        this.router.navigate(['login'])
        alert('Thay đổi mật khẩu thành công! Vui lòng đăng nhập lại')
        return
      } 

      if (response.message) {
        this.errMsg = response.message
      }
    }, err => {
      this.loading = false
      this.errMsg = JSON.stringify(err)
    })
  }

}
