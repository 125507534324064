import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs'

const KEY_TOKEN = 'access_token';
const KEY_EMAIL = 'email';
const KEY_USER = 'user';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  redirectUrl: string;
  
  user: any

  constructor(private _http: HttpClient) { }

  getHeaderToken(): string {
    return localStorage.getItem(KEY_TOKEN);
  }

  storeToken(token: string) {
    localStorage.setItem(KEY_TOKEN, token);
  }

  storeUser(user) {
    this.user = user
    localStorage.setItem(KEY_USER, JSON.stringify(user));
  }

  /**
   * Kiểm tra quyền admin 
   */
  isAdmin() {
    const user = this.getUser();
    return user && user.type == 1
  }

  /**
   * Kiểm tra quyền tư vấn viên  
   */
   isConsultant() {
    const user = this.getUser();
    return user && user.consultant
  }

  /**
   * Kiểm tra quyền Đối tác
   * @returns 
   */
  isAgent() {
    const user = this.getUser();
    return user && user.type == 2
  }

  /**
   * Kiểm tra quyền phân tích 
   */
  isAnalystic() {
    const user = this.getUser();
    return user && user.type == 4
  }

  /**
   * Kiểm tra quyền sửa bài  
   */
  editableData() {
    const user = this.getUser();
    return user && user.type != 5
  }

  /**
   * Kiểm tra quyền quản lý tư vấn
   */
  isManageConsultant() {
    const user = this.getUser();
    return user && (user.type == 1 || user.type == 4)
  }

  getUser(): any {
    if (this.user) {
      return this.user
    } else {
      return JSON.parse(localStorage.getItem(KEY_USER))
    }
  }

  logout() {
    this.user = null
    localStorage.removeItem(KEY_TOKEN);
  }

  authenticate(email: string, password) {
    return this._http.post('/api/auth/sign_in', {
      email: email,
      password: password
    });
  }
  
  isLoggedIn(): boolean {
    return localStorage.getItem(KEY_TOKEN) != null
  }

}
