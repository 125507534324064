export class User {
    _id: string
    name: string
    name_search: string
    doitac_id: string
    so_tai_khoan_ngan_hang: string
    ngan_hang: string
    chi_nhanh: string
    email: string
    type: number
    /**
     * 1: Admin - manager 
     * 2: Đối tác
     * 3: Tư vấn viên
     * 4: Phân tích
     * 5: In ấn
     */
    password: string
    phone: string
    address: string

    consultant: boolean // la tu van vien ?

    // đếm bài
    count: number

    // tổng bài đã mua
    total: number

    created: Date
    active: boolean

    color: string

    day_num: number
    start_date: Date
    typeAnalyst: Array<TypeAnalyst>;
}

export class TypeAnalyst {
    _id?: string;
    typeAnalystId: string;
    typeAnalystName: string;
    count: number;
    total: number;
}

export class DataExport {
    STT: string
    HoTen: string
    SDT: string
    Email: string
    NgaySinh: string
    DiaChi: string
    DaiLy: string
    TuVanVien: string
    DaTuVan: string
    DaPhanTich: string
    NgayTao: string
    NgayPhanTich: string
    TenFile: string
}

export class DaiLyExport {
    STT: string
    NGAY_TAO: Date
    MA_DOI_TAC: string
    HoTen: string
    Email: string
    SDT: string
    TaiKhoaNganHang: string
    KhaNangTuVan
    SoBai
    TypeAnalyst: Array<TypeAnalyst>

    typeAnalyst_0_Name: string;
    typeAnalyst_0_TongSo: string;
    typeAnalyst_0_DaDung: string;
    typeAnalyst_0_ConLai: string;
}

export class Consultant {
    _id: string
    name: string
    phone: string
    email: string
    address: string
}

export class Response<T> {
    success: boolean
    data: T
    datas: T[]
    message: string
}

export class Record {
    index: number
    _id: string
    name: string
    phone: string
    email: string
    address: string
    dob: Date
    date_expired: Date

    scan_file_name: string // Đường dẫn lưu trữ file vân tay khách hàng
    pdf_file_name: string   //

    consultant: string
    creator: string

    created_date: Date
    analysis_date: Date
    read_date: Date
    update_at: Date

    finish: boolean

    active: boolean // Sau 2 tháng sẽ xóa file khách hàng => chuyển active = false

    color: string = '#00FFFFFF'

    // Đối tác có yêu cầu in bài hay không?
    print: boolean = false

    // Khi nào nhân viên in bài xong sẽ bật cờ in xong lên để Đối tác biết.
    in_xong: boolean = false

    assigned: any // nguoi tu van


    xac_nhan_trang_thai: string
    nhan_vien_xac_nhan: string
    chuthich_xac_nhan: string
    is_admin_confirm_status: number
    
    ma_nguoi_tu_van: string
    tien_tu_van: string
    is_pay_money: boolean  // Đã thoanh toán tiền cho Đại lý hay chưa thanh toán


}

export class TypeAnalazy {
    _id: string
    name: string
    phone: string
    email: string
    address: string
}
