import { ExpiredLinkComponent } from './views/error/expired-link.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MyHttpLogInterceptor } from './http.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcelService } from './excel.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';

// FORM
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';

import { MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './CustomPaginatorConfiguration';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule
} from '@coreui/angular'

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { NewloginComponent } from './views/login/newlogin.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgentManagerComponent } from './views/agent-manager/agent-manager.component';
import { ConsultantManageComponent } from './views/consultant-manage/consultant-manage.component';
import { LogoutComponent } from './views/logout/logout.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ResetPasswordComponent } from './reset-password/reset-password.component';



export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    OwlDateTimeModule, 
    OwlNativeDateTimeModule
  ],

  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    NewloginComponent,
    RegisterComponent,
    NewloginComponent,
    AgentManagerComponent,
    ConsultantManageComponent,
    LogoutComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ExpiredLinkComponent,
    ],

  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpLogInterceptor, 
      multi:true
    },
      ExcelService,
      {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
  ],
  bootstrap: [ AppComponent ],
  exports:[
    CommonModule,
  ]
})

export class AppModule { }