
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';




@Injectable()
export class MyHttpLogInterceptor implements HttpInterceptor {

  constructor(private router: Router, private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authHeader = this.injector.get(DataService).getHeaderToken();

    var request: HttpRequest<any>;
    
    if (authHeader) {
         request = req.clone({
            headers: req.headers.set('x-access-token', authHeader)
          });         
    }

    return next.handle(request || req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (this.needLogin(err.status)) {
          this.navigateToLogin();
        }
      }
    }),catchError(response => {
      if (response instanceof HttpErrorResponse) {
          if (this.needLogin(response.status)) {
              this.navigateToLogin();
            }
      }
      return observableThrowError(response);
    }),);
  }

  navigateToLogin() {
    this.router.navigate(['/login'], {
    });
  }

  needLogin(status): boolean {
    return status === 401 || status === 403;
  }
  
}