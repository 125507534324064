import { ExpiredLinkComponent } from './views/error/expired-link.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { LogoutComponent } from './views/logout/logout.component';
import { AgentManagerComponent } from './views/agent-manager/agent-manager.component';
import { NewloginComponent } from './views/login/newlogin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';
import { ConsultantManageComponent } from './views/consultant-manage/consultant-manage.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'expired-link',
    component: ExpiredLinkComponent,
    data: {
      title: 'Expired Link'
    }
  },
  {
    path: 'login',
    component: NewloginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'daily',
        loadChildren: './views/daily/daily.module#DailyModule',

      },
      {
        path: 'customer',
        loadChildren: './views/customer/customer.module#CustomerModule'
      },
      {
        path: 'type-analazies',
        loadChildren: './views/type-analazies/type-analazies.module#TypeAnalaziesModule'
      },
      {
        path: 'agent-manager',
        component: AgentManagerComponent,
        data: {
          title: 'Agent Manager'
        }
      },

      {
        path: 'consultant-manager',
        component: ConsultantManageComponent,
        data: {
          title: 'Consultant Manager'
        }
      },
      {
        path: 'change-pass',
        component: ChangePasswordComponent,
        data: {
          title: 'Đổi mật khẩu'
        }
      },
      {
        path: 'logout',
        component: LogoutComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
