import { User } from './../../app.model';
import { DataService } from '../../data.service';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})

export class DefaultLayoutComponent {

  // menuQLTV: any = {
  //   name: 'Quản lý tư vấn',
  //   url: '/consultant-manager',
  //   icon: 'icon-list'
  // }


  menuQLDL: any = {
    name: 'Quản lý đối tác',
    url: '/daily',
    icon: 'icon-list'
  }

  menuChangePass: any = {
    name: 'Đổi password',
    url: '/change-pass',
    icon: 'icon-list'
  }

  menuLogout: any = {
    name: 'Đăng xuất',
    url: '/logout',
    icon: 'icon-list'
  }

  menuNew = {
    name: 'Gửi bài phân tích',
    url: '/dashboard/data/new',
    icon: 'icon-pencil'
  }

  menuCustomer: any = {
    name: 'Quản lý khách hàng',
    url :'/customer/list',
    icon: 'icon-list'
  }

  menuTypeAnalazies: any = {
    name: 'Quản lý loại hình',
    url : '/type-analazies/list',
    icon: 'icon-list'
  }

  divider: any = {
    divider: true
  }

  public navItems = [
    this.divider,
    {
      title: true,
      name:'Dữ liệu khách hàng'
    },
    {
      name: 'Danh sách phân tích',
      url: '/dashboard/data',
      icon: 'icon-list'
    },
  ]

  
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;


  user: User

  chucvu: string
  
  constructor(private http: HttpClient, dataService: DataService) {
    this.user = dataService.getUser()

    if (this.user) {
      if (this.user.type == 1) {
        // Tổng
        this.navItems.push(this.menuQLDL)  ;
        
        this.navItems.push(this.divider);
        this.navItems.push(this.menuCustomer);
        this.navItems.push(this.menuTypeAnalazies);
        // this.navItems.push(this.menuQLTV)
        this.chucvu = 'Chức vụ: '
      } else if (this.user.type == 2) {
        // Dai ly 
        this.navItems.push(this.menuNew)
        this.navItems.push(this.menuCustomer);
        this.chucvu = 'Đối tác: '
      } else if(this.user.type == 3) {
        // TU van vien 
        this.chucvu = 'Tư vấn viên: '
      } else if(this.user.type ==4){
        // Phan tich 
        this.chucvu = 'CV Phân Tích: '
      } else if (this.user.type == 5) {
        this.chucvu = 'NV In ấn'
      }

      this.navItems.push(this.divider);
      this.navItems.push(this.menuChangePass);
      this.navItems.push(this.menuLogout);
      console.log('navitem', this.navItems);
    }
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized')
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }
}
