import { HttpClient } from '@angular/common/http';
import { User } from './../../app.model';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-consultant-manage',
  templateUrl: './consultant-manage.component.html',
  styleUrls: ['./consultant-manage.component.scss']
})

export class ConsultantManageComponent implements OnInit {

  loading = false

  datas: User[]

  @ViewChild("addConsultantModal") addConsultantModal;

  edittingAgent: User = new User()
  currentUser: User 

  errorMessage: string

  editMode: Boolean = false

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getAllAgent()
  }

  getAllAgent() {
    this.errorMessage = undefined
    this.http.get<any>('/api/user/consultant').subscribe(response => {
      if (response.success) {
        this.datas = response.datas
        console.log(response.datas);
      } else {
        this.errorMessage = response.message
      }
    }, err => {
      console.log(err);
    })
  }

  bestCopyEver(src) {
    return Object.assign({}, src);
  }

  showDialogAdd() {
    this.editMode = false
    this.errorMessage = undefined
    this.edittingAgent = new User()
    this.addConsultantModal.show()
  }

  showEdit(user: User) {
    this.editMode = true
    this.errorMessage = undefined
    this.edittingAgent = this.bestCopyEver(user)
    this.currentUser = this.bestCopyEver(user)
    this.addConsultantModal.show()
  }

  addAgent() {
    if (this.loading) {
      return
    }
    this.errorMessage = undefined

    console.log(this.edittingAgent);
    if (this.validateData()) {
  
      this.loading = true
  
      this.http.post<any>('/api/user/new', {
        name: this.edittingAgent.name,
        email: this.edittingAgent.email,
        password: this.edittingAgent.password,
        phone: this.edittingAgent.phone,
        address: this.edittingAgent.address,
        type: 3
      }).subscribe(response => {
        console.log(response +'');
        this.loading = false
  
        if (response.success) {
          this.addConsultantModal.hide()
          this.getAllAgent()
        } else {
          this.errorMessage = ''+ response.message
        }
      }, err => {
        console.log(err)
        this.loading = false
        if (err && err.message) {
          this.errorMessage = 'Lỗi: ' + err.message
        } else {
          this.errorMessage = 'Unknown Error !'
        }
      })
    }
  }

  update() {
    if (this.loading) {
      return
    }
    this.errorMessage = undefined

    if (this.validateData()) {
  
      this.loading = true
  
      this.http.put<any>('/api/user/'+ this.edittingAgent._id, {
        name: this.edittingAgent.name,
        email: this.edittingAgent.email,
        phone: this.edittingAgent.phone,
        address: this.edittingAgent.address,
        active: this.edittingAgent.active,
      }).subscribe(response => {
        console.log(response +'');
        this.loading = false
  
        if (response.success) {
          this.addConsultantModal.hide()
          this.getAllAgent()
        } else {
          this.errorMessage = ''+ response.message
        }
      }, err => {
        console.log(err)
        this.loading = false
        if (err && err.message) {
          this.errorMessage = 'Lỗi: ' + err.message
        } else {
          this.errorMessage = 'Unknown Error !'
        }
      })
    }
  }

  validateData(): Boolean {
    if (!this.edittingAgent.name || this.edittingAgent.name.length <= 4) {
      this.errorMessage = "Tên Đối tác cần > 4 kí tự!"
      return false
    }

    if (!this.edittingAgent.email || !this.validateEmail(this.edittingAgent.email)) {
      this.errorMessage = "Cần nhập địa chỉ email hợp lệ!"
      return false
    }

    if (!this.editMode && (!this.edittingAgent.password || this.edittingAgent.password.length < 8)) {
      this.errorMessage = "Password chưa hợp lệ cần >= 8 kí tự!"
      return false
    }

    if (!this.edittingAgent.phone || this.edittingAgent.phone.length <9) {
      this.errorMessage = "Số phone chưa hợp lệ cần >= 9 kí tự!"
      return false
    }

    this.errorMessage = undefined
    return true
  }

  validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  toggleStatus() {
    this.edittingAgent.active = !this.currentUser.active
    this.update()
  }

}
