import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string
  loading: boolean = false

  link: String 

  success = false
  errMsg: String
  
  constructor(private http: HttpClient, private router: Router) {

  }

  ngOnInit() {
    this.link =  'http://' + location.host
  }

  /**
   * Send email to reset password
   */
  sendEmail() {

    if (this.loading) {
      return
    }

    this.loading = true
    this.http.post<any>('/api/auth/reset-password', {
      email: this.email,
      host: location.host
    }).subscribe ( response => {
      this.loading = false
      this.success = response.success

      if (!response.success) {
        this.errMsg = response.message
      }
    }, err => {
      this.loading = false
      this.errMsg = err.error.message
    })
    
  }

}
