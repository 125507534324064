import { DataService } from './../../data.service';
import { HttpClient } from '@angular/common/http';
import { User } from './../../app.model';
import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-agent-manager',
  templateUrl: './agent-manager.component.html',
  styleUrls: ['./agent-manager.component.scss']
})
export class AgentManagerComponent implements OnInit {
  loading = false

  datas: User[]

  @ViewChild("addAgentModal") addAgentModal;

  edittingAgent: User = new User()
  currentUser: User

  errorMessage: string

  editMode: Boolean = false

  thembai: number

  // user type 
  type: number

  constructor(private http: HttpClient, private dataService: DataService) { }

  ngOnInit() {
    this.type = this.dataService.getUser().type
    this.getAllAgent()
  }

  getAllAgent() {
    this.errorMessage = undefined
    this.http.get<any>('/api/user/agent').subscribe(response => {
      if (response.success) {
        this.datas = response.datas
        this.handleData()
      } else {
        this.errorMessage = response.message
      }
    }, err => {
      console.log(err);
    })
  }

  // xử lý cảnh báo user
  handleData() {
    this.datas.forEach(data => {

      
      if (data.type === 1) {
        // Tổng => không cần show màu 
        data.color = "white"
      } else if (!data.active) {
        data.color = "#64636B"
      } else if (data.count >= data.total) {
        data.color = "#C90A36"
      } else if (data.count/data.total > 0.9) {
        data.color = "#FC6520"
      } else if (data.count/data.total > 0.8) {
        data.color = "#FCDF32"
      } else {
        data.color = "white"
      }
    });
  }

  getPackpageName() {

  }

  bestCopyEver(src) {
    return Object.assign({}, src);
  }

  showDialogAdd() {
    this.thembai = undefined
    this.editMode = false
    this.errorMessage = undefined
    this.edittingAgent = new User()
    this.addAgentModal.show()
  }

  showEdit(user: User) {
    this.thembai = undefined
    this.editMode = true
    this.errorMessage = undefined
    this.edittingAgent = this.bestCopyEver(user)
    this.currentUser = this.bestCopyEver(user) 
    this.addAgentModal.show()
  }

  addAgent() {
    if (this.loading) {
      return
    }
    this.errorMessage = undefined
    if (this.validateData()) {
  
      this.loading = true
  
      this.http.post<any>('/api/user/new', {
        name: this.edittingAgent.name,
        email: this.edittingAgent.email,
        password: this.edittingAgent.password,
        phone: this.edittingAgent.phone,
        address: this.edittingAgent.address,
        type: 2
      }).subscribe(response => {
        this.loading = false
  
        if (response.success) {
          this.getAllAgent()
          this.addAgentModal.hide()
        } else {
          this.errorMessage = ''+ response.message
        }
      }, err => {
        this.loading = false
        if (err && err.message) {
          this.errorMessage = 'Lỗi: ' + err.message
        } else {
          this.errorMessage = 'Unknown Error !'
        }
      })
    }
  }

  update() {
    if (this.loading) {
      return
    }
    this.errorMessage = undefined

    console.log(this.edittingAgent);
    if (this.validateData()) {
  
      this.loading = true

      this.http.put<any>('/api/user/'+ this.edittingAgent._id, {
        name: this.edittingAgent.name,
        email: this.edittingAgent.email,
        phone: this.edittingAgent.phone,
        address: this.edittingAgent.address,
        active: this.edittingAgent.active,
        thembai: this.thembai
      }).subscribe(response => {
        console.log(response +'');
        this.loading = false
  
        if (response.success) {
          this.getAllAgent()
          this.addAgentModal.hide()
        } else {
          this.errorMessage = ''+ response.message
        }
      }, err => {
        console.log(err)
        this.loading = false
        if (err && err.message) {
          this.errorMessage = 'Lỗi: ' + err.message
        } else {
          this.errorMessage = 'Unknown Error !'
        }
      })
    }
  }

  validateData(): Boolean {
    if (!this.edittingAgent.name || this.edittingAgent.name.length <= 4) {
      this.errorMessage = "Tên đối tác cần > 4 kí tự!"
      return false
    }

    if (!this.edittingAgent.email || !this.validateEmail(this.edittingAgent.email)) {
      this.errorMessage = "Cần nhập địa chỉ email hợp lệ!"
      return false
    }

    if (!this.editMode && (!this.edittingAgent.password || this.edittingAgent.password.length < 8)) {
      this.errorMessage = "Password chưa hợp lệ cần >= 8 kí tự!"
      return false
    }

    if (!this.edittingAgent.phone || this.edittingAgent.phone.length <9) {
      this.errorMessage = "Số phone chưa hợp lệ cần >= 9 kí tự!"
      return false
    }

    this.errorMessage = undefined
    return true
  }

  validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  toggleStatus() {
    this.edittingAgent.active = !this.currentUser.active
    this.update()
  }

}
