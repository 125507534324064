import { Component, OnInit } from '@angular/core'
import { Router } from "@angular/router"
import { DataService } from '../../data.service'
import { HttpClient } from '@angular/common/http'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrls: ['./newlogin.component.scss']
})
export class NewloginComponent implements OnInit {

  returnUrl: string = "/dashboard"
  email: string
  password: string

  loading: boolean = false

  error: boolean = false
  errorMessage: String

  loginForm: FormGroup

  constructor(private router: Router, private dataService: DataService, private http: HttpClient, private fb: FormBuilder) { 
    if(dataService.redirectUrl) {
      this.returnUrl = dataService.redirectUrl
    }

    this.createForm()
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [ 
          Validators.required,
          Validators.email
      ]),
      password: new FormControl('', [
          Validators.minLength(8), 
          Validators.required
      ])
    });
  }

  ngOnInit() {
    
  }

  public login() {
    this.errorMessage = undefined
    if (this.loginForm.valid) {
      this.loading = true
      this.http.post<any>('/api/auth/sign_in', {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      }).subscribe(response => {
        console.log(JSON.stringify(response));
        this.loading = false
        if(response.token) {
          if (response.user.active) {
            this.dataService.storeToken(response.token);
            this.dataService.storeUser(response.user)
            this.router.navigate(['/dashboard'])
          } else {
            this.errorMessage = 'Tài khoản của bạn hiện tạm khóa, Liên hệ với RONTALENT để được mở'
          }
        } else if(response.message) {
          this.errorMessage = ''+ response.message
        } else {
          this.errorMessage = 'unknown error'
        }
      }, err => {
        this.loading = false
        if (err && err.message) {
          this.errorMessage = '' + err.message
        } else {
          this.errorMessage = 'Unknown Error !'
        }
      })
    } else {
      if (this.loginForm.controls.email) {
        if (this.loginForm.controls.email.invalid) {
          this.errorMessage = 'Địa chỉ email không hợp lệ'
        } else if(this.loginForm.controls.password) {
          this.errorMessage = 'Password ít nhất 8 kí tự'
        } else {
          this.errorMessage = 'Vui lòng nhập password'
        }
      } else {
        this.errorMessage = 'Vui lòng nhập email'
      }
    }
  }

  public regist() {
    this.router.navigate(["/register"])
  }

  public forgotPass() {
    this.router.navigate(['forgot-password'])
  }

}
